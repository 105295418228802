import { Helmet } from "react-helmet";
import { SeoBasicsProps } from "./SeoBasicsInterfaces";

const fallbackTitle = "politikjam";

export const SeoBasics = (props: SeoBasicsProps): JSX.Element => {
  return (
    <Helmet>
      <meta name="description" content={props.description || fallbackTitle} />

      <title>{props.title || fallbackTitle}</title>
    </Helmet>
  );
};
