import { makeStyles } from "tss-react/mui";

export const useProjektTemplateStyles = makeStyles()((theme) => ({
  centerHeadline: {
    textAlign: "center",
    width: "90%",
  },
  centerText: {
    textAlign: "center",
    width: "70%",
    "@media (max-width: 742px)": {
      width: "90%",
    },
  },
  marginButton: {
    marginBottom: theme.spacing(1),
  },
  avatarMargin: {
    marginTop: theme.spacing(6),
  },
  jungendpresseImage: {
    margin: theme.spacing(2),
    width: "100px",
    marginLeft: "50px",
    marginTop: "-10px",
    "@media (max-width: 1100px)": {
      paddingBottom: "10px",
      marginRight: theme.spacing(2),
    },
    "@media (max-width: 765px)": {
      paddingBottom: "10px",
      marginRight: theme.spacing(2),
      marginTop: "-40px",
    },

    "@media (max-width: 469px)": {
      paddingBottom: "10px",
      marginRight: theme.spacing(3),
      marginTop: "40px",
    },
  },
  jugendstrategieImage: {
    margin: theme.spacing(2),
    width: "160px",
    marginTop: "-5px",

    "@media (max-width: 1100px)": {
      marginTop: "-10px",
    },
    "@media (max-width: 551px)": {
      marginTop: "-40px",
    },
    "@media (max-width: 496px)": {
      marginTop: "-10px",
    },
  },
  bmfsfjImage: {
    // Wieso geht das nicht?
    // marginTop: "100px !important",
    margin: theme.spacing(2),
    width: "200px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  firstParagraph: {
    "@media (max-width: 765px)": {
      marginBottom: "-5px",
    },
  },
  secoundGridContainerCrew: {
    justifyContent: "center",
  },
  // wenn contrast mode -> secondary
  instagramLink: {
    margin: "0",
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: "3px",
  },
}));
