import { Avatar, Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { staticSeoTexts } from "../../../data/staticSeoTexts";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { ProjektTemplateProps } from "./ProjektTemplateInterfaces";
import { useProjektTemplateStyles } from "./ProjektTemplateStyles";

const ProjektTemplate = (props: ProjektTemplateProps): JSX.Element => {
  const { classes, cx } = useProjektTemplateStyles();

  return (
    <Box container={true} direction="column" alignItems="center">
      <SeoBasics {...staticSeoTexts.aboutUs} />

      <Grid
        container={true}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mb={2.5}
        mt={2.5}
      >
        <Typography className={classes.centerHeadline} mb={2} variant="h1">
          politikjam <br /> Jugendliche. Journalismus. Jam.
        </Typography>

        <Typography
          className={cx(classes.centerHeadline, classes.firstParagraph)}
          mb={2}
          variant="body1"
        >
          politikjam ist ein Projekt des Jugendpresse Deutschland e.V. und wird
          gefördert vom Jugend-Budget des Bundesministerium für Familie,
          Senioren, Frauen und Jugend.
        </Typography>

        <Box className={classes.wrapper}>
          <MuiLink
            component={GatsbyLink}
            target="_blank"
            rel="noreferrer"
            to={`https://www.bmfsfj.de/`}
          >
            <img
              src="/partnerlogos/bmfsfj_gefoerdert.jpg"
              className={classes.bmfsfjImage}
            />
          </MuiLink>

          <MuiLink
            component={GatsbyLink}
            target="_blank"
            rel="noreferrer"
            to={`https://jugendstrategie.de/`}
          >
            <img
              src="/partnerlogos/jugendstrategie.png"
              className={classes.jugendstrategieImage}
            />
          </MuiLink>

          <MuiLink
            component={GatsbyLink}
            target="_blank"
            rel="noreferrer"
            to={`https://jugendpresse.de/`}
          >
            <img
              src="/partnerlogos/jugendpresse.png"
              className={classes.jungendpresseImage}
            />
          </MuiLink>
        </Box>

        <Typography mb={4} className={classes.centerText} variant="body1">
          In dieser Online-Redaktion kommt eure Meinung als Jugendliche nicht zu
          kurz, denn ihr bestimmt selbst, worüber ihr Beiträge machen wollt.
          Egal ob als festes Mitglied der Redaktion oder als freie*r Content
          Creator, ob in Form eines Podcasts, Textes, Films oder einer Grafik –
          wir bieten deinem Beitrag eine Plattform oder helfen dir, ihn zu
          entwickeln und umzusetzen. Dabei ist es egal, ob du schon
          journalistische Vorkenntnisse hast, oder das erste Mal in diesen
          Bereich reinschnuppern möchtest. Hauptsache, dir liegt das Thema
          Politik genauso am Herzen wie uns!
        </Typography>

        <Typography mb={2} variant="h1">
          Die Crew
        </Typography>

        <Typography mb={-4} className={classes.centerText} variant="body1">
          Moderiert wird eure Jamsession von der politikjam-Crew – Elena,
          Florian, Sarah, Sophie und Timon. Sie versorgen euch mit Wissenswertem
          über Politik und Journalismus, organisieren entsprechende Workshops
          für die Redakteur*innen, veranstalten regelmäßig Redaktionssitzungen
          und halten den Laden zusammen.
        </Typography>
      </Grid>

      <Box mb={4}>
        <Grid
          container={true}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {/* Elena___ */}

          <Grid
            direction="column"
            container={true}
            alignItems="center"
            item={true}
            xs={12}
            md={6}
          >
            <Avatar
              className={cx(classes.marginButton, classes.avatarMargin)}
              sx={{ width: 120, height: 120 }}
              src="/team/elena.jpg"
            >
              IMAGE
            </Avatar>

            <Typography className={classes.marginButton} variant="body1">
              Elena
            </Typography>

            <Typography className={classes.centerText} variant="body1">
              Elena ist im erweiterten Bundesvorstand der Jugendpresse und macht
              gerade ein Volontariat beim Göttinger Tageblatt, nachdem sie
              vergangenes Jahr ihr Politikwissenschaftsstudium abgeschlossen
              hat. „Schade, dass es politikjam vor zehn Jahren noch nicht gab,
              als ich meine ersten Schritte im Journalismus gemacht habe. Umso
              glücklicher bin ich, jetzt das Projekt begleiten zu dürfen.”
            </Typography>
          </Grid>

          {/* Florian___ */}

          <Grid
            direction="column"
            container={true}
            alignItems="center"
            item={true}
            xs={12}
            md={6}
          >
            <Avatar
              className={cx(classes.marginButton, classes.avatarMargin)}
              sx={{ width: 120, height: 120 }}
              src="/team/florian.png"
            >
              IMAGE
            </Avatar>

            <Typography className={classes.marginButton} variant="body1">
              Florian
            </Typography>

            <Typography className={classes.centerText} variant="body1">
              Florian begleitet politikjam als Bundesvorstand der Jugendpresse.
              Nebenbei studiert er Politikwissenschaft in Hannover, arbeitet als
              HiWi an seinem Institut und schreibt in freier Mitarbeit für die
              Hannoversche Allgemeine Zeitung. "Man hat selten die Chance so
              überregional und dabei inhaltlich frei ein Magazin auf die Beine
              zu stellen. Ich kann gut verstehen, dass viele Jugendliche sich
              bei politikjam ausprobieren".
            </Typography>
          </Grid>
        </Grid>

        {/* Sarah___ */}

        <Grid
          container={true}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className={classes.secoundGridContainerCrew}
        >
          <Grid
            direction="column"
            container={true}
            alignItems="center"
            item={true}
            xs={12}
            md={6}
            mb={1}
            className={classes.secoundGridContainerCrew}
          >
            <Avatar
              className={cx(classes.marginButton, classes.avatarMargin)}
              sx={{ width: 120, height: 120 }}
              src="/team/sarah.jpg"
            >
              IMAGE
            </Avatar>

            <Typography className={classes.marginButton} variant="body1">
              Sarah
            </Typography>

            <Typography className={classes.centerText} variant="body1">
              Sarah hat sich in und auch neben ihrem Studium viel mit
              Jugendarbeit beschäftigt und ihr liegt die Mitbestimmung und
              Sichtbarkeit von Jugendlichen besonders am Herzen. Im Rahmen einer
              Ausbildung zur Radioredakteurin und -moderatorin hat sie
              praktische Erfahrung im journalistischen Arbeiten gesammelt.
              „politikjam verbindet perfekt meine beiden Herzensthemen:
              partizipative Jugendarbeit und Journalismus.“
            </Typography>
          </Grid>

          {/* Sophie___ */}

          <Grid
            direction="column"
            container={true}
            alignItems="center"
            item={true}
            xs={12}
            md={6}
          >
            <Avatar
              className={cx(classes.marginButton, classes.avatarMargin)}
              sx={{ width: 120, height: 120 }}
              src="/team/sophie.jpg"
            >
              IMAGE
            </Avatar>

            <Typography className={classes.marginButton} variant="body1">
              Sophie
            </Typography>

            <Typography className={classes.centerText} variant="body1">
              Sophie hat nach dem Abitur für verschiedene Zeitungen und Magazine
              geschrieben und inzwischen ihr Studium in Germanistik und Gender
              Studies abgeschlossen. „Als ich Praktikantin in verschiedenen
              Redaktionen war, störte mich am meisten, dass mir oft einfach
              vorgegeben wurde, worüber ich schreiben sollte. Die
              Redakteur*innen selbst über die Inhalte von politikjam entscheiden
              zu lassen, ist das spannendste daran, das Projekt zu leiten.“
            </Typography>
          </Grid>

          {/* Timon___ */}

          <Grid
            direction="column"
            container={true}
            alignItems="center"
            item={true}
            xs={12}
            md={6}
          >
            <Avatar
              className={cx(classes.marginButton, classes.avatarMargin)}
              sx={{ width: 120, height: 120 }}
              src="/team/timon.jpg"
            >
              IMAGE
            </Avatar>

            <Typography className={classes.marginButton} variant="body1">
              Timon
            </Typography>

            <Typography className={classes.centerText} variant="body1">
              Timon macht gerade sein freiwilliges Jahr bei der Jugendpresse und
              ist seit Anfang an bei politikjam dabei. „Ich finde, dass es
              wichtig ist, junge Perspektiven in der Politik und in unserer
              Gesellschaft zu beachten und ihnen eine Plattform zu bieten. Mit
              politikjam wird genau das ermöglicht!“
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container={true}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mb={2.5}
        mt={2.5}
        xs={12}
      >
        <Typography mb={4} className={classes.centerText} variant="body1">
          Du hast einen Beitrag in der Schublade, der endlich veröffentlicht
          gehört? Oder du überlegst, Teil unseres Projekts zu werden und
          möchtest an einer unserer regelmäßigen Online-Redaktionssitzungen
          teilnehmen?
        </Typography>

        <Typography mb={4} className={classes.centerText} variant="h3">
          Dann melde dich bei bei uns unter{" "}
          <MuiLink href={`mailto:kontakt@politikjam.de`}>
            kontakt@politikjam.de
          </MuiLink>{" "}
          oder via{" "}
          <MuiLink
            target="_blank"
            underline="always"
            href={`https://www.instagram.com/politikjam/`}
          >
            <Typography className={classes.instagramLink} variant="h3">
              Instagram
            </Typography>
          </MuiLink>
        </Typography>
      </Grid>
    </Box>
  );
};

export default ProjektTemplate;
