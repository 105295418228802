import { Container } from "@mui/material";
import ProjektTemplate from "../04-templates/ProjektTemplate/ProjektTemplate";

export const AboutUsPage = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <ProjektTemplate />
    </Container>
  );
};

export default AboutUsPage;
